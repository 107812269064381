<template>
	<div class="page">
		<div class="settings">
			<h3 class="text-primary text-center">Settings</h3>
			<div class="control">
				<label>Display Currency</label>
				<select v-model="newCurrencyCode">
					<optgroup label="Fiat">
						<option value="usd">USD</option>
						<option value="cad">CAD</option>
						<option value="cny">CNY</option>
						<option value="eur">EUR</option>
						<option value="gbp">GBP</option>
						<option value="jpy">JPY</option>
						<option value="rub">RUB</option>
					</optgroup>
					<optgroup label="Crypto">
						<option value="btc">BTC</option>
					</optgroup>
				</select>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
	computed: {
		...mapState(['currencyCode'])
	},
	data() {
		return {
			newCurrencyCode: 'usd'
		};
	},
	created() {
		this.newCurrencyCode = this.currencyCode;
	},
	methods: {
		...mapActions(['setCurrencyCode'])
	},
	watch: {
		newCurrencyCode(val) {
			this.setCurrencyCode(val);
		}
	}
};
</script>

<style lang="stylus" scoped>
.page {
	display: grid;
	align-content: safe center;
	justify-items: center;
}

.settings {
	width: 100%;
	padding: 15px;
	max-width: 500px;
}

h3 {
	margin-bottom: 15px;
}
</style>